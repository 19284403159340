import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMicrophoneLines,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

const MenuIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  gap: 3rem;

  a {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;
    color: darkblue;
    text-decoration: none;
    font-size: 1.5rem;
    &:hover {
      color: blue;
      text-decoration: none;
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      <MenuIcons>
        <Link
          to={`/events/${DateTime.now()
            .toFormat("cccc-LLLL-d-yyyy")
            .toLowerCase()}`}
        >
          <FontAwesomeIcon icon={faMicrophoneLines} />
          Karaoke
        </Link>
        <Link to={`/artists`}>
          <FontAwesomeIcon icon={faUser} />
          Artists / KJs
        </Link>
        <Link to={`/venues`}>
          <FontAwesomeIcon icon={faBuilding} />
          Venues
        </Link>
      </MenuIcons>
    </Layout>
  );
};

export default IndexPage;
